import React from 'react'
import './AddMastering.scss'

export default function AddMastering(props) {
    return (
        <div className='container'>
            {props.mix.fileAmountOne ||
             props.mix.fileAmountTwo ||
             props.mix.fileAmountThree ||
             props.mix.fileAmountFour ||
             props.mix.fileAmountFive ?
             <div className='addMastering'>
                {/* <h3>Add mastering</h3> */}
                <form onChange={(i) => props.registry(i)}>
                    <input 
                        type='checkbox'
                        id='addMastering' 
                        name='addMastering'
                        defaultChecked={props.data.addMastering}
                        value={props.data.addMastering}>
                        </input>
                    <label for="addMastering">Add mastering</label>
                </form>
            </div> : null }
        </div>
    )
}
