import React from 'react'

export default function Type(props) {
    return (
        <div className='row'>
            <div className='amount'></div>
            <div className='subject'><h4>{props.s}</h4></div>
            <div className='price'>{`€ ${props.p[props.i]}`}</div>
        </div>
    )
}
