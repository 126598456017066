import React from 'react'
import './Mastering.scss'

export default function Mastering(props) {
    const typeString = Object.keys(props.type).toString();
    return (
        <div className='mastering'>
            {typeString === 'mastering' ?
                <><h3>Select song amount</h3>
                <form onChange={(i) => props.registry(i)}>
                    <input 
                        type='radio' 
                        id='songAmountOne' 
                        name='masteringFileAmount' 
                        defaultChecked={props.master.songAmountOne}
                        value={props.master.songAmountOne}></input>
                    <label  for="songAmountOne">1</label>
                    <input 
                        type='radio' 
                        id='songAmountTwo' 
                        name='masteringFileAmount' 
                        defaultChecked={props.master.songAmountTwo}
                        value={props.master.songAmountTwo}></input>
                    <label for="songAmountTwo">2</label>
                    <input 
                        type='radio' 
                        id='songAmountThree' 
                        name='masteringFileAmount' 
                        defaultChecked={props.master.songAmountThree}
                        value={props.master.songAmountThree}></input>
                    <label for="songAmountThree">3</label>
                </form></>
            : null}
            <h3>Mastering options</h3>
            <form onChange={(i) => props.registry(i)}>
                <div className='block'>
                    <input 
                        type='checkbox' 
                        id='instrumentalMaster' 
                        defaultChecked={props.type.mixing || props.type.mixingAndMastering || props.data.instrumentalMaster}
                        value={props.data.instrumentalMaster}></input>
                    <label for="instrumentalMaster">
                        Instrumental master
                        {(props.type.mixing && props.data.addMastering) || props.type.mixingAndMastering ? <span className='free'><p>free</p></span> : null}
                    </label>
                </div>
                <div className='block'>
                    <input 
                        type='checkbox' 
                        id='streamingOptimized' 
                        defaultChecked={props.type.mixing || props.type.mixingAndMastering || props.data.streamingOptimized}
                        value={props.data.streamingOptimized}></input>
                    <label for="streamingOptimized">
                        Streaming optimized version
                        {(props.type.mixing && props.data.addMastering) || props.type.mixingAndMastering ? <span className='free'><p>free</p></span> : null}
                    </label>
                </div>
                <div className='block'>
                    <input 
                        type='checkbox' 
                        id='stemMastering' 
                        defaultChecked={props.data.stemMastering}
                        value={props.data.stemMastering}></input>
                    <label for="stemMastering">Stem mastering</label>
                    {props.data.stemMastering ? 
                        <select id='stemMastering'>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select> : null}
                </div>
                <div className='block'>
                    <input 
                        type='checkbox' 
                        id='additionalVersions' 
                        defaultChecked={props.data.additionalVersions}
                        value={props.data.additionalVersions}></input>
                    <label for="additionalVersions">Additional versions</label>
                    {props.data.additionalVersions ? 
                        <select id='additionalVersions'>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select> : null}
                </div>
            </form>
        </div>
    )
}
