import React from 'react'
import './Notes.scss'
import TextareaAutosize from 'react-textarea-autosize';

export default function Notes(props) {
    const rowAmount = '3';

    return (
        <div className='notes'>
            <h3>Let us know what you want</h3>
            <h3>Audio editing and extra options for mixing</h3>
            <form onChange={(i) => props.registry(i)}>
                <label>please fill in yadda yadda</label>
                <TextareaAutosize id='notes' type='text' minRows={rowAmount}/>
            </form>
        </div>
    )
}
