import React from 'react'
import './User.scss';

export default function User(props) {
    return (
        <div className='user'>
            <h3>Your info</h3>
            <form onChange={(i) => props.registry(i)}>
                <input id='firstName' placeholder='First name' type="text" value={props.passed.firstName}></input>
                <input id='lastName' placeholder='Last name' type="text" value={props.passed.lastName}></input>
                <input id='email' placeholder='info@maas.audio' type="email" value={props.passed.email}></input>
                <input id='company' placeholder='Company or Artist name' type="text" value={props.passed.company}></input>
                <input id='phone' placeholder='Phone' type="phone" value={props.passed.phone}></input>
                <select>
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="opel">Opel</option>
                    <option value="audi">Audi</option>
                </select>
            </form>
        </div>
    )
}
