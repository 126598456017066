import React from 'react'
import './Service.scss'
let config = require('../../config');

export default function Service(props) {
    return (
        <div className='service'>
            <h3>Choose your service</h3>
            <form onChange={(i) => props.registry(i)}>
                <input type='radio' id='mixing' name='serviceType' value={config.mixing}></input>
                <label for="mixing">Mixing</label>
                <input type='radio' id='mastering' name='serviceType' value={config.mastering}></input>
                <label for="mastering">Mastering</label>
                <input type='radio' id='mixingAndMastering' name='serviceType' value={config.mixingAndMastering}></input>
                <label for="mixingAndMastering">Mixing & Mastering</label>
            </form>
        </div>
    )
}
