import React from 'react'

export default function Row(props) {

    function calc() {
        return parseFloat(props.a[props.i] || 1) * parseFloat(props.p[props.i]);
    }

    console.log(props)

    return (
        <div className='row'>
            <div className='amount'>
                {props.a[props.i] || 1}
            </div>
            <div className='subject'>
                {props.s}
            </div>
            <div className='price'>
                {(props.free && (props.d.addMastering || props.t.mixingAndMastering)) 
                 && !props.t.mastering ? <span className='free'><p>FREE</p></span> : `€ ${calc()}`}
            </div>
        </div>
    )
}

// {(d.addMastering || both) && !mastering ? <span className='free'><p>FREE</p></span> : null}