import React from 'react'

export default function Total(props) {
    return (
        <div className='row'>
            <div className='amount'></div>
            <div className='subject'>
                Subtotal
            </div>
            <div className='price'>
                € {props.calc().toString()}
            </div>
        </div>
    )
}
