import React, { useEffect, useState } from 'react';

import './App.scss';

import User from './components/User/User';
import Mixing from './components/Mixing/Mixing';
import Mastering from './components/Mastering/Mastering';
import AddMastering from './components/AddMastering/AddMastering';
import Notes from './components/Notes/Notes';
import Extra from './components/Extra/Extra';
import Summary from './components/Summary/Summary';
import Service from './components/Service/Service';

let config = require('./config');

function App() {

  const [customer, setCustomer] = useState({});
  const [data, setData] = useState({});
  const [type, setType] = useState({});
  const [amount, setAmount] = useState({});
  const [mix, setMix] = useState({});
  const [master, setMaster] = useState({});
  const [notes, setNotes] = useState({});

  const godData = {customer, type, data, amount, mix, master, notes};
  const GDPR = {data, type, amount, mix, master, notes};

  useEffect(() => {
    //window.sessionStorage.setItem('session', JSON.stringify(GDPR))
  })

  function saveCustomer(i) {
    setCustomer({...customer, [i.target.id]: i.target.value});
  }

  function erase() {
    // erase choices from object
    setData({})
    setMix({})
    setMaster({})
  }

  function saveType(i) {
    setType({[i.target.id]: i.target.checked})
    erase()
    calculator()
  }

  function saveMastering(i) {
    setData({...data, [i.target.id]: i.target.checked})
    // add function that adds the value of the 'free' items to Object

    // setData({...data, instrumentalMaster: i.target.checked})
    // setData({...data, streamingOptimized: i.target.checked})
  }

  function saveToObject(i) {
    i.target.type === 'select-one' ? setAmount({...amount, [i.target.id]: i.target.value}) :
    i.target.type === 'radio' && i.target.name === 'mixingFileAmount' ? setMix({[i.target.id]: i.target.checked}) :
    i.target.type === 'radio' && i.target.name === 'masteringFileAmount' ? setMaster({[i.target.id]: i.target.checked}) :
    i.target.type !== 'radio' ? setData({...data, [i.target.id]: i.target.checked}) : setData({...data, [i.target.id]: config[i.target.id]}) ;
    console.log('gd: ', godData);
    calculator()
  }

  function saveNotes(i) {
    setNotes({...notes, [i.target.id]: i.target.value});
  }

  function calculator() {
    let reducer = 0;

    // insert code to filter out TYPE
    Object.keys(type).map(key => {
      type[key] ? reducer += config[key] : console.log('nah') ;
    })
    
    // map over keys, if key is true, value is active
    // and thus should be multiplied by one or more (amount).
    Object.keys(data).map(key => {
      data[key] ? reducer += config[key] * (amount[key] || 1) : console.log('nah') ;
    })
    // same for (mix) file amount
    Object.keys(mix).map(key => {
      mix[key] ? reducer += config[key] : console.log('nah') ;
    })
    // same for (master) song amount
    Object.keys(master).map(key => {
      master[key] ? reducer += config[key] : console.log('nah') ;
    })

    console.log('calc total: ', reducer);

    return reducer;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Maas Audio</h1>
      </header>
      <main>
        <User registry={saveCustomer} passed={customer}/>
        <Service registry={saveType}/>
        {type.mixing || type.mixingAndMastering ? <Mixing registry={saveToObject} data={data} mix={mix}/> : null}
        {(!type.mastering || !type.mixingAndMastering) && type.mixing ? <AddMastering registry={saveMastering} data={data} mix={mix}/> : null}
        {data.addMastering || type.mixingAndMastering || type.mastering ? <Mastering registry={saveToObject} data={data} type={type} master={master}/> : null}
        <Summary passed={godData} config={config} calc={calculator}/>
        {type.mixing || type.mastering || type.mixingAndMastering ? <Extra registry={saveNotes} data={data}/>: null}
        {type.mixing || type.mastering || type.mixingAndMastering ? <Notes registry={saveNotes}/> : null}
      </main>
    </div>
  );
}

export default App;
