import React from 'react'
import './Extra.scss'
import TextareaAutosize from 'react-textarea-autosize';

export default function Extra(props) {
    const rowAmount = '3';
    const d = props.data;

    return (
        <div className='extra'>
            {d.vocalTuning || d.breathsAndFades || d.timingEditing || d.stemFiles ? 
            <>
                <h3>Please fill in your extra info</h3>
                <h3>Audio editing and extra options for mixing</h3>
                <form onChange={(i) => props.registry(i)}>
                    {d.vocalTuning ? <div className='field'>
                        <label>tuned</label>
                        <TextareaAutosize id='tuned' type='text' minRows={rowAmount}/>
                    </div> : null}
                    {d.breathsAndFades ? <div className='field'>
                        <label>breaths</label>
                        <TextareaAutosize id='breaths' type='text' minRows={rowAmount}/>
                    </div> : null}
                    {d.timingEditing ? <div className='field'>
                        <label>timing</label>
                        <TextareaAutosize id='timing' type='text' minRows={rowAmount}/>
                    </div> : null}
                    {d.stemFiles ? <div className='field'>
                        <label>stems</label>
                        <TextareaAutosize id='stems' type='text' minRows={rowAmount}/>
                    </div> : null}
                </form>
            </> : null}
        </div>
    )
}
