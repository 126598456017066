import React from 'react'

export default function Row(props) {
    return (
        <div className='row'>
            <div className='amount'></div>
            <div className='subject'><h4 className='subtitle'>{props.s}</h4></div>
            <div className='price'></div>
        </div>
    )
}
