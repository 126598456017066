import React from 'react'
import './Mixing.scss'

export default function Mixing(props) {

    return (
        <div className='mixing'>
            <div className='amount'>
                <h3>Select file amount</h3>
                <form onChange={(i) => props.registry(i)}>
                    <input 
                        type='radio' 
                        name='mixingFileAmount' 
                        id='fileAmountOne' 
                        defaultChecked={props.mix.fileAmountOne} 
                        value={props.mix.fileAmountOne}></input>
                    <label for="fileAmountOne">1 – 4</label>
                    <input 
                        type='radio' 
                        name='mixingFileAmount' 
                        id='fileAmountTwo' 
                        defaultChecked={props.mix.fileAmountTwo} 
                        value={props.mix.fileAmountTwo}></input>
                    <label for="fileAmountTwo">5 – 8</label>
                    <input 
                        type='radio' 
                        name='mixingFileAmount' 
                        id='fileAmountThree' 
                        defaultChecked={props.mix.fileAmountThree} 
                        value={props.mix.fileAmountThree}></input>
                    <label for="fileAmountThree">9 – 24</label>
                    <input 
                        type='radio' 
                        name='mixingFileAmount' 
                        id='fileAmountFour'                     
                        defaultChecked={props.mix.fileAmountFour} 
                        value={props.mix.fileAmountFour}></input>
                    <label for="fileAmountFour">25 – 48</label>
                    <input 
                        type='radio' 
                        name='mixingFileAmount' 
                        id='fileAmountFive' 
                        defaultChecked={props.mix.fileAmountFive} 
                        value={props.mix.fileAmountFive}></input>
                    <label for="fileAmountFive">49 – 64</label>
                </form>
            </div>
            <div className='container'>
                {props.mix.fileAmountOne ||
                 props.mix.fileAmountTwo ||
                 props.mix.fileAmountThree ||
                 props.mix.fileAmountFour ||
                 props.mix.fileAmountFive ? 
                 <div className='options'>
                    <form onChange={(i) => props.registry(i)}>
                        <h3>Audio editing options</h3>
                        <div className='block'>
                        <input 
                            type='checkbox' 
                            name='mixingEditingOptions' 
                            id='vocalTuning' 
                            defaultChecked={props.data.vocalTuning} 
                            value={props.data.vocalTuning}></input>
                        <label for="vocalTuning">Vocal/Instrument tuning</label>
                        {props.data.vocalTuning ? 
                            <select id="vocalTuning">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select> : null}
                        </div>
                        <div className='block'>
                        <input 
                            type='checkbox' 
                            name='mixingEditingOptions' 
                            id='breathsAndFades' 
                            defaultChecked={props.data.breathsAndFades}
                            value={props.data.breathsAndFades}></input>
                        <label for="breathsAndFades">Breaths and fades</label>
                        {props.data.breathsAndFades ? 
                            <select id="breathsAndFades">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select> : null}
                        </div>
                        <div className='block'>
                        <input 
                            type='checkbox' 
                            name='mixingEditingOptions' 
                            id='timingEditing' 
                            defaultChecked={props.data.timingEditing}
                            value={props.data.timingEditing}></input>
                        <label for="timingEditing">Timing editing</label>
                        {props.data.timingEditing ? 
                            <select id='timingEditing'>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select> : null}
                        </div>
                    </form>
                </div> : null }
                {props.mix.fileAmountOne ||
                 props.mix.fileAmountTwo ||
                 props.mix.fileAmountThree ||
                 props.mix.fileAmountFour ||
                 props.mix.fileAmountFive ? 
                 <div className='options'>
                    <form onChange={(i) => props.registry(i)}>
                        <h3>Extra options</h3>
                        <div className='block'>
                            <input 
                                type='checkbox' 
                                name='mixingExtraOptions' 
                                id='instrumentalFile' 
                                defaultChecked={props.data.instrumentalFile}
                                value={props.data.instrumentalFile}></input>
                            <label for="instrumentalFile">Instrumental file</label>
                        </div>
                        <div className='block'>
                            <input 
                                type='checkbox' 
                                name='mixingExtraOptions' 
                                id='acapellaFile' 
                                defaultChecked={props.data.acapellaFile}
                                value={props.data.acapellaFile}></input>
                            <label for="acapellaFile">Acapella file</label>
                        </div>
                        <div className='block'>
                            <input 
                                type='checkbox' 
                                name='mixingExtraOptions' 
                                id='stemFiles' 
                                defaultChecked={props.data.stemFiles}
                                value={props.data.stemFiles}></input>
                            <label for="stemFiles">Stem files</label>
                            {props.data.stemFiles ? 
                                <select id='stemFiles'>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </select> : null}
                        </div>
                    </form>
                </div> : null }
            </div>
        </div>
    )
}
