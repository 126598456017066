import React from 'react'
import './Summary.scss'
import Row from './Row/Row';
import Type from './Type/Type';
import Title from './Title/Title';
import Total from './Total/Total';

export default function Summary(props) {
    const c = props.passed.customer;
    const d = props.passed.data;
    const t = props.passed.type;
    const a = props.passed.amount;
    const mi = props.passed.mix;
    const ma = props.passed.master;
    const p = props.config

    const sT = Object.keys(t).toString()
    const mixing = t.mixing;
    const mastering = t.mastering;
    const both = t.mixingAndMastering;

    const all = 
        d.vocalTuning || 
        d.breathsAndFades || 
        d.timingEditing || 
        d.instrumentalFile || 
        d.acapellaFile || 
        d.stemFiles;

    return (
        <div className='summary'>
            {/* client name */}
            {c.firstName ? <Title s={`${c.firstName} quote`} p={p}/> : null}
            {/* mix types */}
            {sT === 'mixing'                ? <Title s={'Mixing'} p={p}/> : null}
            {sT === 'mastering'             ? <Title s={'Mastering'} p={p}/> : null}
            {sT === 'mixingAndMastering'    ? <Title s={'Mixing and mastering'} p={p}/> : null}
            {/* file amount */}
            {(mixing || both ) && mi.fileAmountOne    ? <Type i={'fileAmountOne'} type={'File'} s={'1 – 4 files'} a={a} p={p}/> : 
             (mixing || both ) && mi.fileAmountTwo    ? <Type i={'fileAmountTwo'} type={'File'} s={'5 – 8 files'} a={a} p={p}/> :
             (mixing || both ) && mi.fileAmountThree  ? <Type i={'fileAmountThree'} type={'File'} s={'9 – 24 files'} a={a} p={p}/> :
             (mixing || both ) && mi.fileAmountFour   ? <Type i={'fileAmountFour'} type={'File'} s={'25 – 48 files'} a={a} p={p}/> :
             (mixing || both ) && mi.fileAmountFive   ? <Type i={'fileAmountFive'} type={'File'} s={'49 – 64 files'} a={a} p={p}/> : null}
             {/* song amount */}
            {(mastering || both ) && ma.songAmountOne   ? <Type i={'songAmountOne'} type={'Song'} s={'1 song'} a={a} p={p}/> :
             (mastering || both ) && ma.songAmountTwo   ? <Type i={'songAmountTwo'} type={'Song'} s={'2 songs'} a={a} p={p}/> :
             (mastering || both ) && ma.songAmountThree ? <Type i={'songAmountThree'} type={'Song'} s={'3 songs'} a={a} p={p}/> : null}
            {/* extra options title */}
            {all ? <Title s={'Extra options'} p={p}/> : null}
            {/* Audio edit options */}
            {(mixing || both ) && d.vocalTuning         ? <Row i={'vocalTuning'} s={'Vocal/Instrumental tuning'} a={a} p={p}/> : null}
            {(mixing || both ) && d.breathsAndFades     ? <Row i={'breathsAndFades'} s={'Breaths and fades'} a={a} p={p}/> : null}
            {(mixing || both ) && d.timingEditing       ? <Row i={'timingEditing'} s={'Timing editing'} a={a} p={p}/> : null}
            {/* Extra options */}
            {(mixing || both ) && d.instrumentalFile    ? <Row i={'instrumentalFile'} s={'Instrumental file'} a={a} p={p}/> : null}
            {(mixing || both ) && d.acapellaFile        ? <Row i={'acapellaFile'} s={'Acapella file'} a={a} p={p}/> : null}
            {(mixing || both ) && d.stemFiles           ? <Row i={'stemFiles'} s={'Stem files'} a={a} p={p}/> : null}
            {/* add mastering */}
            {(mixing) && d.addMastering     ? <Title s={'Add mastering'} p={p}/> : null}
            {/* mastering options */}
            {(d.addMastering && mixing) && !t.mastering                     ? <Row i={'addMastering'} s={'Mastering'} a={a} p={p} t={t} d={d}/> : null}
            {(d.addMastering || both) && d.instrumentalMaster               ? <Row i={'instrumentalMaster'} s={'Instrumental master'} free={true} a={a} p={p} t={t} d={d}/> : null}
            {(d.addMastering || both) && d.streamingOptimized               ? <Row i={'streamingOptimized'} s={'Streaming optimized version'} free={true} a={a} p={p} t={t} d={d}/> : null}
            {(d.addMastering || mastering || both) && d.stemMastering       ? <Row i={'stemMastering'} s={'Stem mastering'} a={a} p={p}/> : null}
            {(d.addMastering || mastering || both) && d.additionalVersions  ? <Row i={'additionalVersions'} s={'Additional versions'} a={a} p={p}/> : null}
            {t.mixing || t.mastering || t.mixingAndMastering ? <Total t={t} calc={props.calc}/> : null}
        </div>
    )
}
