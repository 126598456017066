module.exports = {
    // services
    mixing: 10,
    mastering: 20,
    mixingAndMastering: 30,
    // mixing amounts
    fileAmountOne: 100,
    fileAmountTwo: 200,
    fileAmountThree: 300,
    fileAmountFour: 400,
    fileAmountFive: 500,
    // audio editing options
    vocalTuning: 10,
    breathsAndFades: 20,
    timingEditing: 30,
    instrumentalFile: 40,
    acapellaFile: 50,
    stemFiles: 60,
    // add mastering
    addMastering: 100,
    // mastering song amount
    songAmountOne: 100,
    songAmountTwo: 200,
    songAmountThree: 300,
    // mastering options
    instrumentalMaster: 808,
    streamingOptimized: 808,
    stemMastering: 808,
    additionalVersions: 808
}